/*
 * @Author: liyxt
 * @Date: 2020-06-05 15:32:32
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-17 10:15:28
 * @Description: file content
 */ 

/**
 * 通用的对象取值方法
 * @param {*} paths 设值的key的路径，如：[key1, key2...]
 */

export default function get(entity, path) {
    let current = entity;

    for (let i = 0; i < path.length; i += 1) {
        if (current === null || current === undefined) {
            return undefined;
        }
        current = current[path[i]];
    }

    return current;
}
/*
 * @Author: liyxt
 * @Date: 2020-04-20 09:44:34
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-17 10:13:05
 * @Description: file content
 */
/**
 * 获取event对象
 * @param  {...any} rest
 * @returns {event}
 */
export function getEventObject(...rest) {
    let eventFromArgs = rest.find(
            e => e && typeof e.preventDefault === 'function',
        ),
        eventFromWindow = window.event,
        defaultEvent = {
            stopPropagation() {
                console.warn('未找到Event对象');
            },
            preventDefault() {
                console.warn('未找到Event对象');
            },
        };
    return eventFromArgs || eventFromWindow || defaultEvent;
}

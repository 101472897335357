/*
 * @Author: liyxt
 * @Date: 2020-04-27 16:07:57
 * @LastEditors: bbq
 * @LastEditTime: 2020-10-29 14:13:04
 * @Description: file content
 */
import { getLangCode, changeTime, formatNumber, formatDatetime, formatAcuracy } from '@platform/api';
import classNames from 'classnames';

import React from 'react';
import {
    Input,
    Checkbox,
    Switch,
    Select,
    MultiLangText,
    TZDatePickerStart,
    TZDatePickerRangeDay,
    TZDatePickClientHourTime,
    TZDatePickClientTime,
    Timepicker,
    TZDatePickerEnd,
    DatePickerNoTimeZone,
    RangePickerClient,
    Refer,
    TextArea,
    NumberInput,
    PopconfirmSwitch,
    Attachment,
    YearPicker,
    MonthPicker,
} from '@platform/base'

const { Option } = Select;
const { ReferLoader } = Refer;

const loginLanguage = getLangCode();

function resolveSelect(props) {
    let {
        options,
        multiple,
        attrcode,
    } = props;
    return {
        component: <Select
            multiple={multiple}
            labelInValue
            popData={{ fieldid: `${attrcode}_select` }}
        >
            {Array.isArray(options) ?
                options.map(({ display, value }, i) => (
                    <Option value={value} key={i} title={display}>
                        {display}
                    </Option>
                )) : []}
        </Select>,
        componentValueToFieldValue: value => {
            if (multiple) {
                value = value || [];
                return {
                    display: value.map(e => e.label).join(','),
                    value: value.map(e => e.key).join(','),
                }
            } else {
                let { key, label } = value || {};
                return {
                    display: label,
                    value: key,
                }
            }
        },
        fieldValueToComponentValue: ({ display, value }) => {
            if (multiple) {
                let splitValues = value ? value.split(',') : [],
                    splitDisplays = display ? display.split(',') : [];
                return splitValues.map((value, i) => {
                    return {
                        key: value,
                        label: splitDisplays[i],
                    }
                })
            } else {
                return {
                    key: value,
                    label: display,
                }
            }
        },
        beforeEventTrigger: 'click',
        afterEventTrigger: multiple ? 'onBlur' : 'onChange',
        getDisplay: getDisplayCreator(props, 'display'),
        focusableElement: multiple ? 'input' : 'div.u-select-selection',
    }
}

function resolveDatepicker(Component, props, config = {}) {
    let { format, attrcode, itemtype } = props;
    let {
        beforeEventTrigger = 'click',
        afterEventTrigger = 'onChange',
    } = config;
    return {
        component: <Component
            format={format}
            fieldid={attrcode}
            autoTriggerChange={false}
        />,
        beforeEventTrigger,
        afterEventTrigger,
        getDisplay: () => {
            let value = getDisplayCreator(props, 'value')();
            switch (itemtype) {
                case 'datetimepicker':
                case 'NCTZDatePickClientTime':
                    value = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                    break;
                case 'NCTZDatePickerStart':
                case 'NCTZDatePickerEnd':
                case 'NCTZDatePickClientHourTime':
                case 'datepicker':
                    value = changeTime(value, 'YYYY-MM-DD');
                    break;
            }

            switch (itemtype) {
                case 'NCTZDatePickerStart':
                case 'NCTZDatePickerEnd':
                case 'NCTZDatePickClientHourTime':
                case 'datePickerNoTimeZone':
                case 'datepicker':
                    value = formatDatetime(value, 'date');
                    break;
                case 'timepicker':
                    value = formatDatetime(value, 'time');
                    break;
                case 'datetimepicker':
                case 'NCTZDatePickClientTime':
                    value = formatDatetime(value, 'datetime');
                    break;
            }
            return value;
        },
        // focusableElement: 'div.DatePicker',
    }
}

function getDisplayCreator(props, defaultKeyToDisplay) {
    return function getDisplay({ whichKeyToDisplay = defaultKeyToDisplay, getFieldValue, attrcode } = props) {
        let fieldValue = getFieldValue({ attrcode });
        if (whichKeyToDisplay === 'auto') {
            return (fieldValue.display === undefined || fieldValue.display === null) ? fieldValue.value : fieldValue.display;
        } else {
            return fieldValue[whichKeyToDisplay] ?? ''
        }
    }
}

function switchDisplayCreator(props) {
    let { getFieldValue, attrcode } = props;
    let value = getFieldValue({ attrcode })?.value;
    return <span>
        <i 
            style={{
                width: '8px',
                height: '8px',
                display: 'inline-block',
                borderRadius: '4px',
                marginRight: '6px',
                backgroundColor: value ? 'rgba(40,202,66,1)' : 'rgba(255,95,87,1)',
            }}
        ></i>
        {getDisplayCreator(props, 'display')()}
    </span>;
}

const configMap = {
    // 无编辑事件类 ————————————————————————————————————————————————————————————————————————————
    label: props => ({
        component: <span className={classNames({
            'label': true,
            'with-border': props.withBorder,
            'disabled': props.disabled,
        })} />,
        valuePropName: 'children',
        fieldValueToComponentValue: () => getDisplayCreator(props, 'auto')(),
        getDisplay: getDisplayCreator(props, 'auto'),
        alwaysEmitBeforeEventWhenClick: props.alwaysEmitBeforeEventWhenClick ?? true,
    }),
    // 编辑前：onFocus 编辑后：onChange ——————————————————————————————————————————————————————————————————
    input: ({ alwaysEmitBeforeEventWhenClick = true } = {}) => ({
        component: <Input type="text" />,
        beforeEventTrigger: 'focus',
        afterEventTrigger: 'onBlur',
        alwaysEmitBeforeEventWhenClick,
    }),
    password: () => ({
        component: <Input type="password" />,
        beforeEventTrigger: 'focus',
        afterEventTrigger: 'onBlur',
        getDisplay: () => '*'.repeat(8),
        // getDisplay: ({ attrcode }) => (getFieldValue({ attrcode }).value || '').replace(/./g, '*'),
    }),
    residtxt: ({ languageMeta, getFieldValue, setFieldValue, attrcode }) => ({
        component: <MultiLangText />,
        beforeEventTrigger: 'focus',
        afterEventTrigger: 'onBlur',
        componentValueToFieldValue: value => {
            let fieldValue = { ...value };
            let mainIndex = languageMeta.find(e => e.index == 1 || e.index == '')?.index || '';
            if (!value.hasOwnProperty(attrcode)) {
                fieldValue[attrcode] = fieldValue[attrcode + mainIndex];
                delete fieldValue[attrcode + mainIndex]
            }
            return fieldValue;
        },
        fieldValueToComponentValue: value => {
            let componentValue = { ...value };
            let mainIndex = languageMeta.find(e => e.index == 1 || e.index == '')?.index || '';
            if (!value.hasOwnProperty(attrcode + mainIndex)) {
                componentValue[attrcode + mainIndex] = componentValue[attrcode];
                delete componentValue[attrcode]
            }
            return componentValue;
        },
        getFieldValue: ({ attrcode }) => {
            // 根据主attrcode获取其余字段的值
            // index == 1 为主语言， attrcode没有后缀
            let value = {};
            Array.isArray(languageMeta) && (value = languageMeta.reduce((value, { index }) => {
                let realCode = index == 1 ? attrcode : attrcode + index;
                value[realCode] = {
                    value: getFieldValue({ attrcode: realCode })?.value || '',
                    index,
                };
                return value;
            }, value));
            return value;
        },
        setFieldValue: ({ attrcode, value, componentValue }) => {
            // 根据主attrcode设置其余字段的值
            // index == 1 为主语言， attrcode没有后缀
            Array.isArray(languageMeta) && languageMeta.forEach(({ index }) => {
                let realCode = index == 1 ? attrcode : attrcode + index;
                if (value[realCode].current) {
                    let { current, ...restValue } = value[realCode]
                    setFieldValue({
                        attrcode: realCode,
                        value: restValue,
                        componentValue,
                    })
                }
            });
        },
        equal: (lastValue, newValue) => {
            if (Object.keys(lastValue).length === Object.keys(newValue).length) {
                return Object.keys(newValue).every(key => newValue[key].value && lastValue[key].value && newValue[key].value === lastValue[key].value);
            }
            return false;
        },
        getDisplay: () => {
            // 优先展示登录语言值，无值时展示主语言值
            let loginValue,
                mainValue;
            Array.isArray(languageMeta) && languageMeta.map(({ index, languageCode }) => {
                if (languageCode === loginLanguage) {
                    // 登录语言值
                    let realCode = index == 1 ? attrcode : attrcode + index;
                    loginValue = getFieldValue({ attrcode: realCode });
                }
                if (index == 1 || index == '') {
                    // 主语言值
                    mainValue = getFieldValue({ attrcode });
                }
            })
            return loginValue?.value || mainValue?.value || '';
        },
    }),
    number: props => {
        let { scale } = props;
        return {
            component: <NumberInput />,
            beforeEventTrigger: 'focus',
            afterEventTrigger: 'onBlur',
            componentValueToFieldValue: value => ({ display: '', value, scale }),
            getDisplay: () => {
                let value = getDisplayCreator(props, 'value')();
                value = formatNumber(formatAcuracy(value, scale));
                return value;
            },
        }
    },
    textarea: () => ({
        component: <TextArea />,
        beforeEventTrigger: 'focus',
        afterEventTrigger: 'onBlur',
        focusableElement: 'textarea',
    }),
    refer: props => {
        return {
            component: <ReferLoader />,
            valuePropName: 'foolValue',
            beforeEventTrigger: 'click',
            componentValueToFieldValue: value => ({
                display: value.display,
                value: value.value,
            }),
            fieldValueToComponentValue: value => value,
            normalize: (value, foolValue) => {
                let copyValue;
                if (Array.isArray(value)) {
                    copyValue = [...value];
                } else {
                    copyValue = { ...value };
                }

                copyValue.value = foolValue.value;
                copyValue.display = foolValue.display;
                return copyValue;
            },
            getDisplay: getDisplayCreator(props, 'display'),
            focusableElement: 'input.refer-input',
        }
    },
    // 编辑前：onClick 编辑后：onChange ——————————————————————————————————————————————————————————————————
    radio: props => resolveSelect({ ...props, multiple: false }),
    checkbox: props => resolveSelect({ ...props, multiple: true }),
    checkbox_switch: props => ({
        component: <Checkbox className="single-checkbox" colors="primary" type="switch" />,
        componentValueToFieldValue: value => ({ value }),
        fieldValueToComponentValue: ({ value }) => !!value,
        valuePropName: 'checked',
        beforeEventTrigger: 'click',
        defaultValue: { value: false },
        getDisplay: () => switchDisplayCreator(props),
        alwaysEmitBeforeEventWhenClick: props.alwaysEmitBeforeEventWhenClick ?? true,
        display: 'inline-block',
        focusableElement: 'input',
        beforeEventMode: 'mousedown',
    }),
    switch: props => ({
        component: <Switch />,
        componentValueToFieldValue: value => ({ value }),
        fieldValueToComponentValue: ({ value }) => !!value,
        valuePropName: 'checked',
        beforeEventTrigger: 'click',
        defaultValue: { value: false },
        getDisplay: () => switchDisplayCreator(props),
        focusableElement: 'span.u-switch',
        alwaysEmitBeforeEventWhenClick: props.alwaysEmitBeforeEventWhenClick ?? true,
        display: 'inline-block',
    }),
    switch_browse: props => ({
        component: props.cancelPSwitch ? <Switch /> : <PopconfirmSwitch />,
        componentValueToFieldValue: value => ({ value }),
        fieldValueToComponentValue: ({ value }) => !!value,
        valuePropName: 'checked',
        beforeEventTrigger: 'click',
        defaultValue: { value: false },
        getDisplay: () => switchDisplayCreator(props),
        focusableElement: 'span.u-switch',
        alwaysEmitBeforeEventWhenClick: props.alwaysEmitBeforeEventWhenClick ?? true,
        display: 'inline-block',
    }),
    NCTZDatePickerStart: props => resolveDatepicker(TZDatePickerStart, props),
    NCTZDatePickerRangeDay: props => resolveDatepicker(TZDatePickerRangeDay, props), // 查询区用
    NCTZDatePickClientHourTime: props => resolveDatepicker(TZDatePickClientHourTime, props),
    datetimepicker: props => resolveDatepicker(TZDatePickClientTime, props, { afterEventTrigger: 'onClose' }),
    timepicker: props => resolveDatepicker(Timepicker, props, { afterEventTrigger: 'onClose' }),
    NCTZDatePickerEnd: props => resolveDatepicker(TZDatePickerEnd, props),
    datePickerNoTimeZone: props => resolveDatepicker(DatePickerNoTimeZone, props),
    datepicker: props => resolveDatepicker(TZDatePickClientHourTime, props),
    rangepicker: props => resolveDatepicker(RangePickerClient, props),
    NCTZDatePickClientTime: props => resolveDatepicker(TZDatePickClientTime, props, { afterEventTrigger: 'onClose' }),
    monthpicker: props => resolveDatepicker(MonthPicker, props),
    yearpicker: props => resolveDatepicker(YearPicker, props),
    select: props => resolveSelect(props),
    attachment: () => {
        return {
            component: <Attachment />,
            valuePropName: 'fieldValue',
            valueChangePropName: 'getCount',
            afterEventTrigger: 'onHide',
            normalize: (billId, fileList) => fileList + ',' + billId,
            beforeEventTrigger: 'click',
        }
    },
}

/**
 * 元数据解析器
 * @param {*} props 
 */
export default function (props = {}) {
    let { attrcode, itemtype, getFieldValue, setFieldValue, alwaysEmitBeforeEventWhenClick } = props,
        defaultConfig = {
            defaultValue: { display: '', value: '' },
            component: null,
            afterEventTrigger: 'onChange', // 触发编辑后事件的时机（表示编辑结束的时机）
            beforeEventTrigger: 'focus', // 触发编辑前事件的时机（表示编辑开始的时机）
            valuePropName: 'value', // 代表组件值的属性
            valueChangePropName: 'onChange', // 代表组件值改变的属性
            normalize: value => value, // 标准化【字段值变更的回调函数】的参数，使其和【字段值属性】保持一致
            componentValueToFieldValue: value => ({ display: '', value }), // 原始值 => 业务值
            fieldValueToComponentValue: ({ value }) => value ?? '', // 业务值 => 原始值
            equal: (lastValue, newValue) => lastValue && newValue && lastValue.value === newValue.value, // 比较新旧值是否相等的函数
            validateTrigger: 'onChange', // 校验时机
            getFieldValue, // 获取字段业务值的方法
            setFieldValue, // 设置字段业务值的方法
            focusableElement: 'input',
            alwaysEmitBeforeEventWhenClick,
            getDisplay: getDisplayCreator(props, 'value'), // displayKey不适用于 password/residtxt/checkbox_switch/switch/switch_browse 类型
            beforeEventMode: 'auto', // 编辑前实现方式：auto-根据浏览器；disabled/mousedown-指定实现方式
        };

    let isValidType = true;

    let itemConfig = (configMap[itemtype] || (({ itemtype }) => { 
        console.warn(`无法解析attrcode为${attrcode}的${itemtype}类型`); 
        isValidType = false;
    }))(props);
    
    return Object.assign({ itemtype }, defaultConfig, itemConfig, { isValidType })
    // return { itemtype, ...defaultConfig, ...itemConfig, isValidType };
}

/*
 * @Author: liyxt
 * @Date: 2020-06-29 09:33:08
 * @LastEditors: bbq
 * @LastEditTime: 2020-11-09 14:12:19
 * @Description: file content
 */
import get from './utils/get';
import set from './utils/set';
import toArray from './utils/toArray';

export class ArrayData {
    isArrayData = true;

    constructor(originData = [], keyPath = '') {
        if (originData.isArrayData) {
            return originData;
        }
        let { data } = this.dataTranslater(originData, toArray(keyPath));
        let newProperties = Object.setPrototypeOf([], Object.getPrototypeOf(originData))
        Object.entries({
            data,
            keyPath: toArray(keyPath),
            isArrayData: this.isArrayData,
            query: this.query,
            update: this.update,
            queryIndexByKey: this.queryIndexByKey,
            splice: this.splice,
            pop: this.pop,
            push: this.push,
            shift: this.shift,
            unshift: this.unshift,
            // sort: this.sort,
            // reverse: this.reverse,
        }).forEach(([key, value]) => {
            Object.defineProperty(newProperties, key, {
                value: typeof value === 'function' ? value.bind(originData) : value,
                enumerable: false,
                writable: key === 'isArrayData' ? true : false,
                configurable: false,
            })
        })
        Object.defineProperty(newProperties, 'order', {
            get() {
                return this.map(e => get(e, this.keyPath));
            },
            enumerable: false,
            configurable: false,
        })

        Object.setPrototypeOf(originData, newProperties)
        return originData;
    }

    /**
     * 数据转换器：将数组转换为易【增删改查】的数据结构
     * @param {Array} originData 
     * @param {Array | String} keyPath 
     */
    dataTranslater(originData, keyPath) {
        let data = {};
        if (Array.isArray(originData)) {
            originData.map(item => {
                let key = get(item, keyPath);
                data[key] = item;
                return key;
            })
        }
        return { data };
    }

    /**
     * 
     * @param {Number} param 整数时，按下标查找 
     * @param {String} param 字符串时，按key查找 
     * @param {Object} param 对象时，按精准条件查找 
     */
    query(param) {
        let key, index;
        if (typeof param === 'number') {
            index = param;
        } else if (typeof param === 'string') {
            key = param;
        } else if (param && typeof param === 'object') {
            ({ key, index } = param);
        }

        if (index !== undefined) {
            return this[index];
        } else if (key !== undefined) {
            return this.data[key];
        } else {
            return null;
        }
    }

    update(param, func) {
        let res = this.query(param);
        let key = get(res, this.keyPath);
        let finalValue = func({ ...res });
        // 保留引用
        Object.keys(this.data[key]).forEach(property => {
            delete this.data[key][property];
        });
        Object.assign(this.data[key], finalValue)
        // 同步key
        set(this.data[key], this.keyPath, key);
        return this;
    }

    queryIndexByKey(key) {
        if (Array.isArray(key)) {
            return key.map(k => this.queryIndexByKey(k))
        } else {
            return this.findIndex(e => get(e, this.keyPath) === key);
        }
    }

    // 还原数组方法
    pop() {
        let element = Array.prototype.pop.call(this);
        let key = get(element, this.keyPath);
        key !== undefined && delete this.data[key];
        return element;
    }

    push(...elements) {
        let length = Array.prototype.push.call(this, ...elements);
        elements.forEach(element => {
            let key = get(element, this.keyPath);
            this.data[key] = element;
        })
        return length;
    }

    shift() {
        let element = Array.prototype.shift.call(this);
        let key = get(element, this.keyPath);
        key !== undefined && delete this.data[key];
        return element;
    }

    unshift(...elements) {
        let length = Array.prototype.unshift.call(this, ...elements);
        elements.forEach(element => {
            let key = get(element, this.keyPath);
            this.data[key] = element;
        })
        return length;
    }

    // sort() {

    // }

    // reverse() {

    // }

    splice(index, length, ...newData) {
        // 操作原数组
        let deleted = Array.prototype.splice.call(this, index, length, ...newData);
        // 删除
        deleted.forEach(element => {
            let key = get(element, this.keyPath);
            delete this.data[key];
        });
        // 插入
        newData.forEach(element => {
            let key = get(element, this.keyPath);
            this.data[key] = element;
        })
        return deleted;
    }
}

export class TreeData {
    isArrayData = true;

    constructor(originData = [], keyPath = '') {
        if (originData.isArrayData) {
            return originData;
        }
        this.arrayData = [];
        this.data = {};
        this.dataTranslater(originData, toArray(keyPath));

        let newProperties = Object.setPrototypeOf([], Object.getPrototypeOf(originData))
        Object.entries({
            keyPath: toArray(keyPath),
            isArrayData: this.isArrayData,
            arrayData: this.arrayData,
            query: this.query,
            update: this.update,
            getArrayData: this.getArrayData,
            get: this.get,
            // splice: this.splice,
            // pop: this.pop,
            // push: this.push,
            // shift: this.shift,
            // unshift: this.unshift,
            // sort: this.sort,
            // reverse: this.reverse,
            // dfs
            // dfs
            // addNode
            // deleteNode
            // editNode
        }).forEach(([key, value]) => {
            Object.defineProperty(newProperties, key, {
                value: typeof value === 'function' ? value.bind(originData) : value,
                enumerable: false,
                writable: key === 'isArrayData' ? true : false,
                configurable: false,
            })
        })

        Object.setPrototypeOf(originData, newProperties);
        this.originData = originData;
        return originData;
    }

    /**
     * ———— 复写父类方法 ————
     * 数据转换器：将树型结构转换为易【增删改查】的数据结构
     * @param {Array} originData 
     * @param {Array | String} keyPath 
     */
    dataTranslater(data, keyPath, parentPath = [], level = 1) {
        if (Array.isArray(data)) {
            data.forEach(item => {
                let key = get(item, keyPath);
                this.data[key] = item;
                this.arrayData.push(item);
                item._parentPath = parentPath;
                item._level = level;
                this.dataTranslater(item.children, keyPath, [...parentPath, key], level + 1);
            })
        }
    }

    /**
     * 
     * @param {String} param 字符串时，按key查找 
     * @param {Object} param 对象时，按精准条件查找 
     */
    query = param => {
        let key;
        if (typeof param === 'string') {
            key = param;
        } else if (param && typeof param === 'object') {
            ({ key } = param);
        }

        if (key !== undefined) {
            return this.data[key]
        } else {
            return null;
        }
    }

    update(param, func) {
        let res = this.query(param);
        let key = get(res, this.keyPath);
        let finalValue = func({ ...res });
        // 保留引用
        Object.keys(this.data[key]).forEach(property => {
            delete this.data[key][property];
        });
        Object.assign(this.data[key], finalValue)
        // 同步key
        set(this.data[key], this.keyPath, key);
        return this;
    }

    get = () => {
        return this.originData;
    }

    getArrayData = () => {
        return this.arrayData;
    }

    // 深度优先遍历
    dfs = (callback, data = this.originData) => {
        data.forEach(node => {
            callback(node);
            if (Array.isArray(node.children)) {
                this.dfs(callback, node.children)
            }
        })
    }

    // 广度优先遍历
    bfs = () => {

    }

    addNode = (nodePaths, newNode) => { }

    deleteNode = nodePaths => { }

    editNode = (nodePaths, newNode) => { }

    // getHooks = () => {
    //     return {
    //         query: this.query,
    //         update: this.update,
    //         get: this.get,
    //         getArrayData: this.getArrayData,
    //         addNode: this.addNode,
    //         deleteNode: this.deleteNode,
    //         editNode: this.editNode,
    //         isTreeData: true,
    //     }
    // }
}

export function NormalMetaData(data) {
    return new ArrayData(data, 'attrcode');
}

export function NormalTreeData(data) {
    return new TreeData(data, 'attrcode');
}

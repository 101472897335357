/**
 * 校验函数
 * @param {any} value 要校验的值
 * @param {rules} options 校验规则
 * @param {stopOnFirstError} options 遇到第一个错误时停止校验
 * @returns {array} 包含错误信息的数组
 */
export function validate (value, options = {}) {
    let { rules = [], stopOnFirstError = false } = options,
        errors = [];

    !Array.isArray(rules) && (rules = [rules]);

    for (let { length = Infinity, required, pattern, type, max, min, scale, validator, transform, message } of rules) {
        if (required && [undefined, null, ''].includes(value)) {
            errors.push(message);
            if (stopOnFirstError) return errors;
        }

        if (type && Object.prototype.toString.call(value).slice(8, -1).toLowerCase() !== type) {
            errors.push(message);
            if (stopOnFirstError) return errors;
        } else {
            // 类型校验通过
            switch (Object.prototype.toString.call(value).slice(8, -1).toLowerCase()) {
                case 'string': 
                    if (value.length > Number(length)) {
                        errors.push(message);
                        if (stopOnFirstError) return errors;
                    }
                    if (pattern && !pattern.test(value)) {
                        errors.push(message);
                        if (stopOnFirstError) return errors;
                    }
                    break;
                case 'number': 
                    if (max && value > Number(max)) {
                        errors.push(message);
                        if (stopOnFirstError) return errors;
                    } else if (min && value < Number(min)) {
                        errors.push(message);
                        if (stopOnFirstError) return errors;
                    }
                    break;
                case 'array': 
                    if (value.length > Number(length)) {
                        errors.push(message);
                        if (stopOnFirstError) return errors;
                    }
                    break;
            }
        }
    }

    return errors;
}

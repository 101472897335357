import React, { Component, createRef, cloneElement, createContext } from 'react';
import { findDOMNode } from 'react-dom';
import omit from 'omit.js';
import classNames from 'classnames';
import defaultItemCreator from './itemCreator';
import { getEventObject } from './utils/getEventObject';

// 先放在base引入
// require('./Item.less');

export const itemCreator = defaultItemCreator;

// 组件信息
export const ItemContext = createContext();

export function ItemProvider(Target) {
    return function ({ itemCreator = defaultItemCreator, ...props }) {
        return <ItemContext.Provider value={{ itemCreator, store: props.store }} >
            <Target {...props} />
        </ItemContext.Provider>
    }
}

// copy from VUEJS/src/core/util/env.js
/**
 * 判断浏览器类型主要用于区分【编辑前事件】的实现方式
 * - webkit内核浏览器(chrome/safari)利用disabled实现
 *  - 优点：不会阻止默认事件，如：文本选中事件、textarea拖拽事件
 *  - 缺点：要分别处理不同组件的兼容性、禁用时个别交互和非禁用时不同
 * - 其余浏览器利用mousedown阻止事件实现
 *  - 优点：不关注组件实现
 *  - 缺点：会阻止默认事件，如：文本选中事件、textarea拖拽事件
 */
const inBrowser = typeof window !== 'undefined';
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isEdge = UA && UA.indexOf('edge/') > 0;
const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;
// console.log(isChrome ? 'is chrome' : 'isn\'t chrome');

// 每个图层一个activeItem
const activeItem = new Map();

// 会导致失焦的键：tab/enter
const blurKeyCode = new Set([9]);

// 鼠标状态
let mousedown = false;
document.addEventListener('mousedown', () => {
    mousedown = true
}, true);
document.addEventListener('mouseup', () => {
    mousedown = false;
}, true);

/**
 * Item状态：
 * - 非激活状态（默认状态，未触发编辑前）
 * - 激活 & 非编辑状态（触发编辑前，但是编辑前返回false）
 * - 激活 & 编辑状态（触发编辑前，编辑前成功）
 * 
 * 如何触发组件激活状态：
 * - 鼠标操作：onClick
 * - 键盘操作：onFocus
 * 
 * 何时触发编辑前事件：
 * - 鼠标操作：每次点击时
 * - 键盘操作：非激活状态的onFocus事件
 * 
 * 如何触发组件失活状态：
 * - 鼠标操作：：其他Item被激活/点击Item以外区域
 * - 键盘操作：其他Item被激活（enter/上下左右）/（tab）
 */

/**
 * 对所有基础组件做兼容的统一包装层
 * 兼容：props差异
 * 兼容：编辑前后事件
 * @param {Function} onAfterEvent 
 * @param {Function} onBeforeEvent 
 * @param {String} attrcode 
 * @param {Function} getFieldValue 从store里取值
 * @param {Function} setFieldValue 向store中设值
 * @param {Boolean} browse 
 * @param {Boolean} editType 编辑态Item类型：normal-直接显示编辑态；toggle-点击时切换为编辑态；
 * @returns {ReactNode}
 */
class SingleItem extends Component {
    static contextType = ItemContext;
    static defaultProps = { 
        editType: 'normal',
        tabIndex: 0,
    }

    itemRef = null
    oldValue = createRef(null)
    componentValue = createRef(null)
    clickOutside = false;

    bindItemRef = instance => {
        this.itemRef = findDOMNode(instance);
    }

    get isActive() { return activeItem.get(this.getContainer()) === this; }
    set isActive(value) { 
        if (value) {
            let lastActiveItem = activeItem.get(this.getContainer());
            if (lastActiveItem !== this) {
                setTimeout(() => {
                    /**
                     * Item的onMouseDown先于组件的onBlur执行
                     * 所以要保证先执行onBlur
                     * 再使组件失活
                     * 解决NCC-84973
                     */
                    lastActiveItem?.deactivate();
                }, 0);
            }
            activeItem.set(this.getContainer(), this);
        } else {
            if (activeItem.get(this.getContainer()) == this) {
                activeItem.set(this.getContainer(), null);
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = { isEditable: false } 
    }

    componentDidMount() {
        let { path, browse } = this.props;
        let { store } = this.context;

        this.cancelRegister = store?.registerField?.(path, this);
        if (!browse) {
            this.bindTabIndex();
            this.addEventListener();
        }
    }

    componentDidUpdate({ disabled: prevDisabled, browse: prevBrowse, tabIndex: prevTabIndex }) {
        let { disabled, browse, tabIndex } = this.props;
        if (prevDisabled !== disabled || tabIndex !== prevTabIndex) {
            this.bindTabIndex();
        }
        if (prevBrowse !== browse) {
            if (browse) {
                this.removeEventListener()
            } else {
                this.bindTabIndex();
                this.addEventListener();
            }
        }
    }

    componentWillUnmount() {
        this.cancelRegister?.();
        this.cancelOutSideClick?.();
        this.removeEventListener();
        this.itemRef = null;
    }

    getContainer = () => {
        if (!this.container) {
            // 绑定状态切换事件
            let lastParent = this.itemRef, parent = null;
            // 解决模态框中的情况
            while (lastParent) {
                parent = lastParent.parentNode;
                if (parent && parent !== document.body) {
                    lastParent = parent;
                } else {
                    break;
                }
            }
            this.container = lastParent;
        }
        return this.container;
    }

    // 点外部时，编辑态切换成浏览态
    handleOutSideClick = () => {
        // 只有mousedown & mouseup都在外面时才deactivate；
        // 选择文本时可能mouseup在外面
        const outSideMousedown = e => {
            if (e.currentTarget.contains(e.target) && !this.itemRef?.contains(e.target)) {
                this.clickOutside = true;
            }
        }

        const outSideMouseup = () => {
            if (this.clickOutside) {
                this.deactivate();
            }
        }

        let container = this.getContainer();
        if (container) {
            container.addEventListener('mousedown', outSideMousedown, true);
            container.addEventListener('mouseup', outSideMouseup, true);
            return () => {
                container.removeEventListener('mousedown', outSideMousedown, true);
                container.removeEventListener('mouseup', outSideMouseup, true);
                this.cancelOutSideClick = null;
            }
        }
    }

    getComponentValue = () => {
        let { attrcode } = this.props;
        let { fieldValueToComponentValue, getFieldValue } = this.context.itemCreator(this.props);
        let fieldValue = getFieldValue({ attrcode });
        let componentValue = fieldValueToComponentValue(fieldValue);

        return componentValue;
    }

    /**
     * 包装编辑前事件的参数
     * @param  {Object} event
     * @returns {Boolean}
     */
    beforeEventHandler = event => {
        let { onBeforeEvent, attrcode, disabled } = this.props;

        if (disabled || this.isEmittingBeforeEvent) {
            return Promise.resolve(false);
        }

        this.isActive = true;
        this.isEmittingBeforeEvent = true;
        
        let { getFieldValue } = this.context.itemCreator(this.props);
        
        if (typeof onBeforeEvent === 'function') {
            let value = getFieldValue({ attrcode }),
                isContinue = onBeforeEvent({
                    value,
                    event,
                });
            return Promise.resolve(isContinue).then(isContinue => {
                this.isContinue = isContinue !== false;
                return this.isContinue;
            });
        } else {
            this.isContinue = true;
            return Promise.resolve(true);
        }
    }

    /**
     * 包装编辑后事件的参数
     * @param  {...any} rest
     */
    afterEventHandler = (...rest) => {
        let { attrcode, onAfterEvent, toggleEditStatusWhenEmitAfterEvent } = this.props;
        let { getFieldValue } = this.context.itemCreator(this.props);
        let { oldValue, componentValue } = this;
        // console.log('afterEvent emit', ...rest);
        let event = getEventObject(...rest),
            value = getFieldValue({ attrcode });

        if (this.dirty) {
            this.dirty = false;
            onAfterEvent({
                value,
                oldValue: oldValue.current,
                componentValue: componentValue.current,
                event,
            });
            oldValue.current = null;
            componentValue.current = null;
        }

        toggleEditStatusWhenEmitAfterEvent && this.deactivate();
        // if (validateTrigger === afterEventTrigger) {
        //     // 编辑后校验
        //     this.validate(value, { rules: { length: 3 } });
        // }
    }

    /**
     * 包装值改变事件的参数
     * @param  {...any} rest
     * @returns {Boolean}
     */
    valueChangeEventHandler = (...rest) => {
        let { attrcode } = this.props;
        let {
            afterEventTrigger, // 触发编辑后事件的时机
            valueChangePropName, // 代表组件值改变的属性
            normalize, // 标准化【字段值变更的回调函数】的参数，使其和【字段值属性】保持一致
            componentValueToFieldValue, // 原始值 => 业务值
            getFieldValue,
            setFieldValue,
        } = this.context.itemCreator(this.props);
        let { oldValue, componentValue } = this;

        // console.log('afterEvent emit', ...rest);
        !oldValue.current && (oldValue.current = getFieldValue({ attrcode }));
        componentValue.current = normalize(...rest);
        let value = componentValueToFieldValue(componentValue.current);
        // Store里存业务值
        this.dirty = true;
        setFieldValue({
            attrcode,
            value,
            componentValue: componentValue.current,
        });

        if (afterEventTrigger === valueChangePropName) {
            this.afterEventHandler(...rest);
        }

        // if (validateTrigger === valueChangePropName) {
        //     // 输入时实时校验
        //     this.validate(value, { rules: { length: 3 } });
        // }
    }

    getDisplay = () => {
        let { renderItem } = this.props;
        let { getDisplay } = itemCreator(this.props);

        let customComponent = typeof renderItem === 'function' ? renderItem({ isEdit: false }) : undefined;
        if (customComponent !== undefined) {
            return customComponent;
        }

        let display = getDisplay();
        return display;
    }

    stopIfNeeded = event => {
        let { target, currentTarget } = event;

        // 点击区域不在spanWrapper里
        if (!currentTarget.contains(target)) return true;

        if (!this.state.isEditable) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.isEmittingBeforeEvent) {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }
    }

    activate = target => {
        let { editType, onStatusChange } = this.props,
            { isEditable } = this.state;

        if (!isEditable) {
            this.setState({ isEditable: true });
        }
        
        this.isEmittingBeforeEvent = false;

        // 依赖isEditable，但由于在异步中执行，所以可以用同步写法
        this.bindTabIndex();

        let { beforeEventTrigger, focusableElement } = this.context.itemCreator(this.props);
        let ele = this.itemRef?.querySelector(focusableElement);

        // 这里的isEditable是上一次的isEditable
        if (editType === 'toggle' && !isEditable) {
            // 对于不支持autofocus属性的组件，要手动聚焦
            ele?.focus();
            typeof onStatusChange === 'function' && onStatusChange({ isEdit: true })
        } else if (target) {
            // 点击触发
            if (!mousedown) {
                // 如果鼠标处于按下状态，则不通过代码触发click，等鼠标抬起后触发原生click
                target.click?.();
            }
            if (beforeEventTrigger === 'focus') {
                target.focus?.();
            }
        } else {
            // focus触发
            ele?.focus();
        }

        if (!this.cancelOutSideClick) {
            this.cancelOutSideClick = this.handleOutSideClick();
        }
    }

    deactivate = () => {
        let { editType, onStatusChange } = this.props,
            { isEditable } = this.state;
        if (isEditable) {
            this.setState({ isEditable: false }, () => {
                this.bindTabIndex();
                switch (editType) {
                    case 'toggle':
                        typeof onStatusChange === 'function' && onStatusChange({ isEdit: false })
                        break;
                    default:
                }
            });
            this.cancelOutSideClick?.();
        }
        this.isActive = false;
    }

    handleClickCapture = event => {
        // chrome下用disabled实现
        // 非chrome下用mousedown实现，需阻止事件
        if (!isChrome || this.context.itemCreator(this.props).beforeEventMode === 'mousedown') {
            let shouldStop = this.stopIfNeeded(event);
            if (shouldStop) return;
        }
        // 正在触发编辑前，阻止点击事件
        if (this.isEmittingBeforeEvent || !event.currentTarget.contains(event.target)) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    handleMouseDownCapture = event => {
        let { target, currentTarget, button } = event;

        if (button === 0 && currentTarget.contains(target)) {
            this.clickOutside = false;

            // 这里阻止本组件获取焦点的同时也会阻止其他组件失焦，故让activeElement失焦
            if (!currentTarget.contains(document.activeElement)) {
                document.activeElement.blur();
            }
            
            // chrome下用disabled实现
            // 非chrome下用mousedown实现，需阻止事件
            if (!isChrome || this.context.itemCreator(this.props).beforeEventMode === 'mousedown') {
                let shouldStop = this.stopIfNeeded(event);
                if (shouldStop) return;
            }

            this.beforeEventHandler(event).then(isContinue => {
                // setTimeout fix: IE下要双击才触发事件
                setTimeout(() => {
                    if (isContinue && this.isActive) {
                        this.activate(target);
                    } else {
                        this.isEmittingBeforeEvent = false;
                    }
                }, 0);
            })
        }
    };

    handleFocus = event => {
        let { target, currentTarget } = event;
        // if (this.isActive) {
        //     event.stopPropagation();
        // }

        if (!mousedown && !this.isActive && target === currentTarget) {
            // onFocus是由自身触发时
            this.beforeEventHandler(event).then(isContinue => {
                if (isContinue && this.isActive) {
                    this.activate();
                } else {
                    this.isEmittingBeforeEvent = false;
                }
            })
        }
    }

    handleBlur = () => {
        if (this.isPressingTab) {
            this.deactivate();
            this.isPressingTab = false;
        }
    }
    
    handleKeyDown = event => {
        if (blurKeyCode.has(event.keyCode || event.which || event.charCode) && event.currentTarget.contains(event.target)) {
            this.isPressingTab = true
        }
    }

    addEventListener = () => {
        this.itemRef.addEventListener('click', this.handleClickCapture, true);
        // this.itemRef.addEventListener('mousedown', this.handleMouseDownCapture, true);
        // this.itemRef.addEventListener('focus', this.handleFocus);
        // this.itemRef.addEventListener('blur', this.handleBlur);
        // this.itemRef.addEventListener('keydown', this.handleKeyDown);
    }

    removeEventListener = () => {
        this.itemRef.removeEventListener('click', this.handleClickCapture, true);
        // this.itemRef.removeEventListener('mousedown', this.handleMouseDownCapture, true);
        // this.itemRef.removeEventListener('focus', this.handleFocus);
        // this.itemRef.removeEventListener('blur', this.handleBlur);
        // this.itemRef.removeEventListener('keydown', this.handleKeyDown);
    }

    /**
     * 处理快捷键
     * 1、默认情况下span有tabIndex，组件的tabIndex为-1
     * 2、span获取焦点会触发编辑前，返回true则让组件获取焦点
     * 3、组件获取焦点后有tabIndex，span的tabIndex为-1
     */
    bindTabIndex = () => {
        let { tabIndex, disabled } = this.props,
            { isEditable } = this.state,
            { focusableElement } = this.context.itemCreator(this.props);

        let ele = this.itemRef?.querySelector(focusableElement);
        ele?.setAttribute('tabindex', isEditable ? tabIndex : -1);
        this.itemRef.setAttribute('tabindex', disabled ? undefined : (isEditable ? -1 : tabIndex));
    }

    render() {
        let { itemCreator } = this.context;

        let {
            browse = false,
            editType = 'normal',
            renderItem,
            useTitle = true,
            disabled,
            autoFocus,
        } = this.props;

        let { isEditable } = this.state;

        let display = this.getDisplay();

        let wrapperSpanProps = {
            style: { width: '100%', display: 'inline-block', verticalAlign: 'middle' },
            className: classNames({
                'template-item-wrapper': true, 
                'template-item-browse': browse || (editType === 'toggle' && !isEditable),
                'template-item-edit': !(browse || (editType === 'toggle' && !isEditable)),
                'fake-disabled': !disabled && !browse && !isEditable && editType === 'normal',
            }),
            title: useTitle && typeof display === 'string' ? display : undefined,
        };

        if (browse) {
            return <Wrapper ref={this.bindItemRef} {...wrapperSpanProps}>
                {display}
            </Wrapper>
        }

        let itemConfig = itemCreator(this.props);

        let {
            afterEventTrigger,
            valueChangePropName,
            valuePropName,
            component,
            beforeEventMode,
        } = itemConfig;

        let propsToPass = omit(this.props, [
            'children',
            'onAfterEvent',
            'onBeforeEvent',
            'getFieldValue',
            'setFieldValue',
            'tabIndex', 
        ]);

        wrapperSpanProps = Object.assign(wrapperSpanProps, {
            onMouseDownCapture: this.handleMouseDownCapture,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onKeyDown: this.handleKeyDown,
            // onClickCapture: this.handleClickCapture,
        })

        if (editType === 'toggle' && !isEditable) {
            return <Wrapper
                ref={this.bindItemRef}
                {...wrapperSpanProps}
            >
                {display}
            </Wrapper>;
        } else {
            let customComponent = typeof renderItem === 'function' ? renderItem({ isEdit: true }) : undefined;
            let renderComponent = customComponent === undefined ? component : customComponent;
            return (
                /**
                 * 统一在组件外层包装一层span用来处理编辑前事件
                 * [afterEventTrigger]与[valueChangePropName]重名时，在[valueChangePropName]里触发[afterEventTrigger]
                 */
                <Wrapper ref={this.bindItemRef} {...wrapperSpanProps}>
                    {renderComponent && cloneElement(renderComponent, {
                        ...propsToPass,
                        [afterEventTrigger]: this.afterEventHandler,
                        [valueChangePropName]: this.valueChangeEventHandler,
                        [valuePropName]: this.getComponentValue(),
                        autoFocus: autoFocus || editType === 'toggle' && isEditable ? true : undefined,
                        // chrome下使用disabled的实现方式，其余浏览器用mousedown的实现方式
                        disabled: disabled || ((isChrome && beforeEventMode !== 'mousedown') ? !isEditable : undefined),
                    })}
                </Wrapper>
            );
        }
    }
}

class Wrapper extends Component {
    render () {
        let { children, ...others } = this.props;
        return <span {...others}>
            <div className="template-item-wrapper-inner" >
                {children}
            </div>
        </span>;
    }
}

class BetweenItem extends Component {
    constructor(props) {
        super(props);
        this.value = [undefined, undefined];
        this.componentValue = [undefined, undefined];
    }

    transformBetweenValue = value => {
        return {
            display: value.map(e => e ? e.display : ''),
            value: value.map(e => e ? e.value : ''),
        }
    }

    getSingleValueByIndex = ({ attrcode }, index) => {
        let { getFieldValue } = this.props;
        let { value, display } = getFieldValue({ attrcode });

        !Array.isArray(value) && (value = [value]);
        !Array.isArray(display) && (display = [display]);

        let singleValue = {
            display: display[index],
            value: value[index],
        };
        return singleValue;
    }

    setSingleValueByIndex = ({ attrcode, value, componentValue }, index) => {
        let { getFieldValue, setFieldValue } = this.props;
        let betweenValue = getFieldValue({ attrcode });

        !Array.isArray(betweenValue.display) && (betweenValue.display = []);
        !Array.isArray(betweenValue.value) && (betweenValue.value = []);

        betweenValue.display[index] = value.display;
        betweenValue.value[index] = value.value;

        this.componentValue[index] = componentValue;

        setFieldValue({ attrcode, value: betweenValue, componentValue: this.componentValue });
    }

    render() {
        let { onBeforeEvent, onAfterEvent, path } = this.props;
        return <>
            <SingleItem
                {...this.props}
                onBeforeEvent={({ value, event }) => {
                    this.value[0] = value;
                    if (typeof onBeforeEvent === 'function') {
                        return onBeforeEvent({ value: this.transformBetweenValue(this.value), event });
                    }
                    return true;
                }}
                onAfterEvent={({ value, event }) => {
                    let oldValue = [...this.value];
                    this.value[0] = value;
                    if (typeof onAfterEvent === 'function') {
                        return onAfterEvent({ value: this.transformBetweenValue(this.value), oldValue: this.transformBetweenValue(oldValue), event, componentValue: this.componentValue, index: 0 });
                    }
                }}
                setFieldValue={({ attrcode, value, componentValue }) => {
                    this.setSingleValueByIndex({ attrcode, value, componentValue }, 0);
                }}
                getFieldValue={({ attrcode }) => {
                    return this.getSingleValueByIndex({ attrcode }, 0)
                }}
                path={path && path.concat(0)}
            />
            <span className="between-item-sep">-</span>
            <SingleItem
                {...this.props}
                onBeforeEvent={({ value, event }) => {
                    this.value[1] = value;
                    if (typeof onBeforeEvent === 'function') {
                        return onBeforeEvent({ value: this.transformBetweenValue(this.value), event });
                    }
                    return true;
                }}
                onAfterEvent={({ value, event }) => {
                    let oldValue = [...this.value];
                    this.value[1] = value;
                    if (typeof onAfterEvent === 'function') {
                        return onAfterEvent({ value: this.transformBetweenValue(this.value), oldValue: this.transformBetweenValue(oldValue), event, componentValue: this.componentValue, index: 1 });
                    }
                }}
                setFieldValue={({ attrcode, value, componentValue }) => {
                    this.setSingleValueByIndex({ attrcode, value, componentValue }, 1);
                }}
                getFieldValue={({ attrcode }) => {
                    return this.getSingleValueByIndex({ attrcode }, 1)
                }}
                path={path && path.concat(1)}
            />
        </>
    }
}

export default class Item extends Component {
    render() {
        let { between, ...others } = this.props;
        return between ? <BetweenItem {...others} /> : <SingleItem {...others} />
    }
}

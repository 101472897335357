/*
 * @Author: liyxt
 * @Date: 2020-06-05 15:29:13
 * @LastEditors: bbq
 * @LastEditTime: 2020-11-16 15:00:44
 * @Description: file content
 */
import { findDOMNode } from 'react-dom';
import get from './utils/get';
import set from './utils/set';
import toArray from './utils/toArray';
import { getSafeRandom } from '@platform/api'

function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export default class BaseStore {
    uuid = uuidv4();

    // 存放store数据
    store = {};

    ctx = null;

    // 存放字段实例
    fieldMap = {};

    components = {};

    updateQueue = [];

    updateItem = paths => {
        paths = toArray(paths);
        let item = get(this.fieldMap, paths);
        if (item) {
            if (typeof item.forceUpdate === 'function') {
                item.forceUpdate();
            } else {
                Object.keys(item).forEach(key => {
                    this.updateItem(paths.concat(key));
                })
            }
        }
    }

    findDOMNode = paths => {
        paths = toArray(paths);
        let item = get(this.fieldMap, paths);
        return findDOMNode(item);
    }

    // 更新组件
    forceUpdate = callback => {
        // 所有绑定此数据源的实例都需要更新
        this.ctx?.forceUpdate(callback);
    };

    // 向store中设值，返回不可变数据
    set(paths, value) {
        paths = toArray(paths);
        this.store = set(this.store, paths, value);
        return this.store;
    }

    // 从store中取值
    get(paths) {
        paths = toArray(paths);
        let value = get(this.store, paths);
        return value;
    }

    // 设置组件实例
    setRef = ctx => {
        this.ctx = ctx;
    };

    // 获取组件实例
    getRef = () => {
        return this.ctx;
    };

    // 移除组件实例
    removeRef = ctx => {
        if (this.ctx === ctx) {
            this.ctx = null;
        }
    }

    registerField = (path, instance) => {
        if (!path) {
            return;
        }
        this.fieldMap = set(this.fieldMap, path, instance);
        return () => {
            this.fieldMap = set(this.fieldMap, path, null);
        }
    }

    _registerField = (path, instance) => {
        if (!path) {
            return;
        }
        if (Array.isArray(path)) {
            path = path.join('_');
        }
        this.fieldMap[path] = instance;
        return () => {
            delete this.fieldMap[path];
        }
    }

    register = (id, instance) => {
        this.components[id] = { 
            current: instance,
            update() {
                instance.forceUpdate();
            },
            focus() {
                instance.focus?.();
            },
            blur() {
                instance.blur?.();
            }, 
        };
    }

    unregister = id => {
        delete this.components[id];
    }

    getComponent = id => {
        return this.components[id];
    }

    updateComponent = id => {
        this.getComponent(id)?.update();
    }

    focusComponent = id => {
        this.getComponent(id)?.focus();
    }

    blurComponent = id => {
        this.getComponent(id)?.blur();
    }

    // 获取钩子
    getAllHooks = () => {
        // times++
        let hooks = this.getHooks();
        let finalHooks = Object.create(Object.defineProperties({}, {
            registerField: {
                value: this.registerField,
                enumerable: false,
                configurable: false,
                writable: false,
            },
        }))

        finalHooks = Object.assign(finalHooks, {
            ...hooks,
            setRef: this.setRef,
            getRef: this.getRef,
            removeRef: this.removeRef,
            setMeta: this.setMeta,
            getMeta: this.getMeta,
            // 获取store实例，方便内部使用
            getInstance: () => this,
            _registerField: this._registerField,
        })

        return finalHooks;
    };
}

export function hookFactory(Store) {
    return function (...rest) {
        let store = new Store(...rest);
        return store.getAllHooks();
    }
}


/*
 * @Author: liyxt
 * @Date: 2020-06-05 15:48:58
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-17 15:29:19
 * @Description: file content
 */ 
import { Component } from 'react';

export default class BaseComponent extends Component {
    constructor(props) {
        super(props);
        props.store.setRef(this);
    }

    // eslint-disable-next-line react/require-render-return
    render() {
        throw '请复写BaseComponent的render方法！'
    }

    componentWillUnmount () {
        this.props.store.removeRef(this);
    }
}
/*
 * @Author: liyxt
 * @Date: 2020-06-17 10:12:18
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-17 10:17:14
 * @Description: file content
 */ 

export default function toArray(value) {
    if (value === undefined || value === null) {
        return [];
    }
    return Array.isArray(value) ? value : [value];
}
/*
 * @Author: liyxt
 * @Date: 2020-06-05 15:32:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-17 10:12:36
 * @Description: file content
 */ 

// /**
//  * 对象设值方法，配合Data使用
//  * @param {*} entity 原始对象
//  * @param {*} paths 设值的key的路径，如：[key1, key2...]，配合Data使用，可匹配函数
//  * @param {*} value 要设置的值
//  */
// export default function set (entity, paths, value, { pattern = [] } = {}) {
//     if (!paths.length) {
//         return entity;
//     }

//     const [path, ...restPath] = paths;
//     const [part, ...restPart] = pattern;
    
//     if (restPath.length) {
//         if (part && part[0] === '#' && typeof entity.query === 'function') {
//             // 函数
//             // TODO: 处理query结果为空的情况
//             set(entity.query({ key: path }), restPath, value, { pattern: restPart })
//         } else {
//             if (!entity[path]) {
//                 if (typeof path === 'number') {
//                     entity[path] = [];
//                 } else {
//                     entity[path] = {};
//                 }
//             } 
//             set(entity[path], restPath, value, { pattern: restPart });
//         }
//     } else {
//         if (part && part[0] === '#' && typeof entity.update === 'function') {
//             entity.update({ key: path }, typeof value === 'function' ? value : () => value)
//         } else {
//             entity[path] = typeof value === 'function' ? value(entity[path]) : value;
//         }
//     }

//     return entity;
// }

/**
 * 通用的对象设值方法，不可变
 * @param {*} entity 原始对象
 * @param {*} paths 设值的key的路径，如：[key1, key2...]
 * @param {*} value 要设置的值
 */
export default function set (entity, paths, value) {
    if (!paths.length) {
        return value;
    }

    const [path, ...restPath] = paths;

    let clone;
    if (!entity && typeof path === 'number') {
        clone = [];
    } else if (Array.isArray(entity)) {
        clone = [...entity];
    } else {
        clone = { ...entity };
    }

    clone[path] = set(clone[path], restPath, value);

    return clone;
}
